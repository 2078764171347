<template>
	<Layout>
		<div class="row justify-content-center mb-5">
			<div class="col-lg-5">
				<div class="text-center faq-title pt-4 pb-4">
					<h2 class="mb-4">Simple pricing for everyone</h2>
					<toggle-button
						id="toggleBtn"
						v-model="showAnnualPlans"
						:width="120"
						:height="40"
						:speed="480"
						:color="{checked: '#626ed4', unchecked: '#626ed4'}"
						:labels="{checked: 'Yearly', unchecked: 'Monthly'}"
					/>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="offset-xl-4 offset-md-3"></div>
			<div class="col-xl-4 col-md-6" v-for="plan in selectedPlans" :key="plan.id">
				<div class="card pricing-box">
					<div class="card-body p-4">
						<div class="media mt-2">
							<i :class="`${plan.icon} h2 align-self-center`"></i>
							<div class="media-body text-right">
								<h4 class="mt-0">{{ plan.title }}</h4>
								<p class="text-muted mb-0">{{ plan.subtitle }}</p>
							</div>
						</div>
						<div class="pricing-features mt-5 pt-2">
							<p>
								<i class="mdi mdi-check text-primary mr-2"></i> Unlimited Projects
							</p>
							<p>
								<i class="mdi mdi-check text-primary mr-2"></i> Unlimited Time Entries
							</p>
							<p>
								<i class="mdi mdi-check text-primary mr-2"></i> Unlimited Tags
							</p>
							<p>
								<i class="mdi mdi-check text-primary mr-2"></i> Access to Upcoming Features
							</p>
						</div>
						<div class="text-center mt-5">
							<h1 class="mb-0">
								<sup>
									<small>£</small>
								</sup>{{ plan.price }}
								<span class="font-size-16">Per {{ plan.period }} </span>
								<span 
									v-if="plan.discount"
									class="font-size-16"
								>(Save £{{ plan.discount }}) </span>
							</h1>
						</div>
						<div class="mt-5 mb-1">
							<b-button
								class="btn btn-success btn-block"
								@click.prevent="goToStripe(plan.period)"
								:disabled="isLoading"
							>
								<i v-if="isLoading" class="fas fa-spin fa-circle-notch"></i>
								<span v-else>Start Subscription</span>
							</b-button>
						</div>
					</div>
				</div>
			</div>
			<!-- end col -->
		</div>
	</Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { ToggleButton } from 'vue-js-toggle-button'
import BillingService from '@/services/billing';

export default {
	name: 'ClientPricing',
	page: {
		title: "Pricing",
		meta: [{ name: "Pricing", content: appConfig.description }]
	},
	components: { 
		Layout,
		PageHeader,
		ToggleButton,
	},
	data() {
		return {
			title: 'Pricing',
			showAnnualPlans: true,
			plans: [
				{
					id: 0,
					title: 'Basic Plan',
					subtitle: 'Monthly Pricing',
					period: 'Month',
					price: '5',
					discount: null,
					icon: 'ion ion-ios-rocket',
				},
				{
					id: 1,
					title: 'Basic Plan',
					subtitle: 'Annual Pricing',
					period: 'Year',
					price: '50',
					discount: '10',
					icon: 'ion ion-ios-rocket',
				},
			],
			isLoading: false,
			isAlreadySubscribed: null,
		};
	},
	computed: {
		userInfo() {
			return this.$store.state.user.userInfo;
		},
		selectedPlans() {
			if (this.showAnnualPlans) {
				return this.plans.filter(plan => plan.period === 'Year');
			} else {
				return this.plans.filter(plan => plan.period === 'Month');
			}
		}
	},
	watch: {
		userInfo({ subscription }) {
			if (subscription.isActive || subscription.isCanceled) {
				this.isAlreadySubscribed = true;
			}
		}
	},
	methods: {
		goToStripe(period) {
			if (this.isAlreadySubscribed) {
				this.goToStripePortal();
			} else {
				this.goToStripeCheckout(period);
			}
		},
		async goToStripeCheckout(period) {
			this.isLoading = true;
			const url = await BillingService.getStripeCheckoutUrl(period);
			window.location = url;
		},
		async goToStripePortal() {
			this.isLoading = true;
			const url = await BillingService.getStripePortalUrl();
			window.location = url;
		}
	},
	mounted() {
		this.$store.dispatch('user/refreshUserInfo');
	}
};
</script>

<style lang="scss" scoped>
#toggleBtn {
	font-size: 14px;
}
</style>